// App.js - WEB
import React, { Component } from 'react';
// Customizable Area Start
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import CatalogueCreate from "../../blocks/catalogue/src/CatalogueCreate.web";
import CatalogueDetails from "../../blocks/catalogue/src/CatalogueDetails.web";

import Analytics from "../../blocks/analytics/src/Analytics";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationUser from "../../blocks/email-account-registration/src/EmailAccountRegistrationUser.web";
import Login from "../../blocks/email-account-login/src/Login";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Payments from "../../blocks/stripepayments/src/Payments.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings from "../../blocks/settings2/src/Settings.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Share from "../../blocks/share/src/Share";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
// import { Router, Route, Route } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Typography } from "@mui/material";
import { PrivateRoute } from './PrivateRoute';
import OrderManagementView from '../../blocks/ordermanagement/src/OrderManagementView';

// Customizable Area End
// Customizable Area Start

const headerStyle = {
  backgroundColor:"#292524",
  height: "79px",
  padding: "16px 32px 16px 32px",
  borderRadius:"0px 0px 8px 8px",
  margin:"0px 25px 25px 0px",
  transition: 'margin-left 0.3s ease',
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  color:"#ffffff"
};

const routingStyle = {
  ...headerStyle,
  height: "calc(100vh - 190px)", 
};

const containerStyle = {
  display: 'flex',
  height: '100vh',
  backgroundColor:"#000000"
};


const contentStyle = {
  flex: '1 1 auto',
  flexDirection:"column",
  justifyContent:"space-between",
  alignItems:"center",
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});
// Customizable Area End
class App extends Component {
  render() {
   // Customizable Area Start
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const user = localStorage.getItem('user');
    const useData = JSON.parse(user);
    const userObj = useData?.data?.attributes;
   // Customizable Area End
    return (
      // Customizable Area Start
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/registration" component={EmailAccountRegistration} />
        <Route exact path="/userRegistration" component={EmailAccountRegistrationUser} />
        <Route>
          <div className="container" style={containerStyle}>
              <HomeScreen />
            <div className="content" style={contentStyle}>
              <div style={headerStyle}>
                <Typography variant='subtitle1' style={{
                  color: "#C2B30A", fontWeight: "700", textTransform: "uppercase",
                  fontSize: "16px",
                  textAlign: "left"
                }}>DASHBOARD</Typography>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "50px" }}>
                  <div>
                    <img src={require("../public/notifications.svg")} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
                    <img src={require("../public/user.png")} />
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                      <Typography variant='subtitle2' style={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '17.78px',
                        textAlign: 'left'
                      }}>{userObj?.first_name || ''} {userObj?.last_name || ''}</Typography>
                      <Typography variant='body2'>{userObj?.email || ''}</Typography>
                    </div>
                  </div>
                  <div style={{
                    height: '36px',
                    borderRadius: '50px',
                    border: '1px solid #009168',
                    display: 'flex',
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "145px"
                  }}>
                    <img src={require("../public/tick-green.svg")} />

                    <Typography variant='subtitle2' style={{
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '17.78px',
                      textAlign: 'left'
                    }}> Bakery Open</Typography>
                  </div>
                </div>
              </div>
              <div style={routingStyle}>
                <Switch>
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <PrivateRoute path="/catalogue" exact component={Catalogue} />
                  <PrivateRoute path="/order-history" component={()=><Typography variant='body2'>Order history screen</Typography>} />
                  <PrivateRoute path="/payments" component={Payments} />
                  <PrivateRoute path="/notifications" component={Pushnotifications} />
                  <PrivateRoute path="/summary" component={Search} />
                  <PrivateRoute path="/legal" component={ShoppingCartOrders} />
                  <PrivateRoute path="/settings" component={Settings} />
                  <PrivateRoute path="/support" component={ShoppingCartOrders} />
                  <PrivateRoute path="/catalogue/catalogue-create" component={CatalogueCreate} />
                  <PrivateRoute path="/catalogue/details" component={CatalogueDetails} />
                  {/* <Route path="*" component={()=><div style={{ border: "1px solid white" }}>Not found PAge</div>} /> */}
                </Switch>
              </div>
            </div>
          </div>
        </Route>
        <Route path="*" component={<div style={{ border: "1px solid white" }}>Not found PAge</div>} />
      </Switch>
      // Customizable Area End
    );
  }
}

export default App;